import React from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";

const FooterContainer = styled.footer`
  background: #2c3e50;
  padding: 30px 15px;
  color: #ecf0f1;
  text-align: center;
  font-family: "Arial", sans-serif;
  position: relative;
  bottom: 0;
  width: 100%;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.3);
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

const Section = styled.div`
  margin-bottom: 15px;
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

const Heading = styled(animated.h3)`
  font-size: 1.3rem;
  margin-bottom: 8px;
  border-bottom: 2px solid #e74c3c;
  display: inline-block;
  padding-bottom: 5px;
`;

const Text = styled(animated.p)`
  font-size: 0.9rem;
  margin: 4px 0;
  color: #bdc3c7;
`;

const Footer = () => {
  const fadeInSpring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 200,
    config: { tension: 170, friction: 26 },
  });

  return (
    <FooterContainer>
      <FooterContent>
        <Section>
          <Heading style={fadeInSpring}>Prime Solutions</Heading>
          <Text style={fadeInSpring}>📧 Primesolutionsusa.co@gmail.com</Text>
          <Text style={fadeInSpring}>📞 +19295408784</Text>
          <Text style={fadeInSpring}>USA</Text>
        </Section>
        <Section>
          <Heading style={fadeInSpring}>Services</Heading>
          <Text style={fadeInSpring}>Web/App Development</Text>
          <Text style={fadeInSpring}>Digital Marketing</Text>
          <Text style={fadeInSpring}>SEO</Text>
          <Text style={fadeInSpring}>UI/UX Design</Text>
        </Section>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
