import React from "react";
import { useParams } from "react-router-dom";
import "./serviceDetail.css";
import Footer from "../footer/Footer";

const serviceDetails = {
  devops: {
    title: "DevOps",
    description:
      "Our DevOps services bridge the gap between development and operations, ensuring continuous delivery and automation across your software development lifecycle.",
    image: require("../../assets/devops.webp"),
  },
  mobile: {
    title: "Mobile Development",
    description:
      "We offer comprehensive mobile development services, creating high-quality apps tailored to your business needs, for both Android and iOS platforms.",
    image: require("../../assets/mobile-development.webp"),
  },
  uiux: {
    title: "UI/UX Design",
    description:
      "Our UI/UX design services focus on creating user-friendly, aesthetically pleasing, and intuitive interfaces that enhance user experience.",
    image: require("../../assets/ui-ux-design.webp"),
  },
  aiml: {
    title: "AI & ML",
    description:
      "Leverage the power of Artificial Intelligence and Machine Learning to drive innovation and gain competitive advantage in your industry.",
    image: require("../../assets/ai-and-ml.webp"),
  },
  seo: {
    title: "SEO",
    description:
      "Our SEO services help your business rank higher in search results, driving more organic traffic and improving your online presence.",
    image: require("../../assets/ui-ux-design.webp"),
  },
  digital: {
    title: "Digital Marketing",
    description:
      "We provide digital marketing services that help you reach your target audience, increase brand awareness, and drive conversions through various online channels.",
    image: require("../../assets/mobile-development.webp"),
  },
};

const ServiceDetail = () => {
  const { serviceId } = useParams();
  const service = serviceDetails[serviceId];

  if (!service) {
    return <p>Service not found</p>;
  }

  return (
    <>
      <div className="service-detail-container">
        <div className="service-detail-inner">
          <img
            src={service.image}
            alt={service.title}
            className="service-detail-img"
          />
          <h1 className="service-detail-title">{service.title}</h1>
          <p className="service-detail-description">{service.description}</p>
          <div className="service-detail-contact">
            <p className="contact-heading">Get in Touch</p>
            <p className="contact-info">
              <strong>Phone:</strong>{" "}
              <a href="tel:+1234567890" className="contact-link">
                📞 +19295408784
              </a>
            </p>
            <p className="contact-info">
              <strong>Email:</strong>{" "}
              <a href="mailto:info@primesolutions.com" className="contact-link">
                Primesolutionsusa.co@gmail.com
              </a>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ServiceDetail;
