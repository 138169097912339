import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./navbar.css";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-logo" onClick={closeMenu}>
          Prime Solutions
        </Link>
        <div className={`navbar-menu ${isOpen ? "active" : ""}`}>
          <ul>
            <li className="navbar-item">
              <Link to="/" className="navbar-link" onClick={closeMenu}>
                Home
              </Link>
            </li>
            <li className="navbar-item">
              <Link to="/services" className="navbar-link" onClick={closeMenu}>
                Services
              </Link>
            </li>
            <li className="navbar-item">
              <Link
                to="/testimonials"
                className="navbar-link"
                onClick={closeMenu}
              >
                Testimonials
              </Link>
            </li>
            <li className="navbar-item">
              <Link to="/partners" className="navbar-link" onClick={closeMenu}>
                Partners
              </Link>
            </li>
            <li className="navbar-item">
              <Link to="/work" className="navbar-link" onClick={closeMenu}>
                Work
              </Link>
            </li>
          </ul>
        </div>
        <div className="hamburger" onClick={toggleMenu}>
          <div className={`line ${isOpen ? "open" : ""}`}></div>
          <div className={`line ${isOpen ? "open" : ""}`}></div>
          <div className={`line ${isOpen ? "open" : ""}`}></div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
