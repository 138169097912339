import React from "react";
import "./partner.css";
import partner from "../../assets/partner.webp";
import Footer from "../footer/Footer";

const Partner = () => {
  return (
    <>
    <div className="partner-container">
      <div className="partner-inner">
        <div className="p-left">
          <img className="p-img" src={partner} alt="Partnership" />
        </div>
        <div className="p-right">
          <p className="p-p1">Decades of Partnership</p>
          <p className="p-p2">
            We are a full-scale development firm that knows the value of lasting
            relationships and can combine focused, detail-oriented action with
            long-term strategic thinking.
          </p>
        </div>
      </div>
    </div>
     <Footer />
     </>
  );
};

export default Partner;
