import React from "react";
import "./home.css";
import homeImg from "../../assets/homeImg.webp";
import Footer from "../footer/Footer";
import Services from "../services/Services";

const Home = () => {
  return (
    <>
      <div className="home-container">
        <div className="home-inner">
          <p className="home-p1">
            Building products, services & everything in between
          </p>
          <p className="home-p2">Power Your Business With Prime Solutions</p>
          <div className="img-div">
            <img className="home-img" src={homeImg} alt="Home" />
          </div>
          <p className="home-p3">
            We help brands make better marketing decisions by delivering
            world-class, scalable insights
          </p>

          <div className="home-introduction">
            <h2>Welcome to Prime Solutions</h2>
            <p>
              At Prime Solutions, we understand that the key to success lies in
              understanding your business needs and delivering solutions that
              not only meet but exceed expectations. Our team of experts is
              dedicated to providing cutting-edge services that help your
              business thrive in today's competitive market.
            </p>
          </div>

          {/* <div className="home-features">
            <h2>What We Offer?</h2>
            <ul className="features-list">
              <li>Custom Software Development</li>
              <li>Innovative UI/UX Design</li>
              <li>Comprehensive Mobile App Solutions</li>
              <li>DevOps and Cloud Integration</li>
              <li>AI & Machine Learning Applications</li>
              <li>SEO and Digital Marketing</li>
            </ul>
            <h1>Check Our Services Section</h1>
          </div> */}

          <div className="home-testimonials">
            <h2>What Our Clients Say</h2>
            <div className="testimonial">
              <p>
                "Prime Solutions transformed our business with their innovative
                solutions. Their team is highly skilled and understood our needs
                perfectly."
              </p>
              <p>- Alex Johnson, CEO of TechCorp</p>
            </div>
            <div className="testimonial">
              <p>
                "The quality of work and attention to detail at Prime Solutions
                is unmatched. They delivered beyond our expectations!"
              </p>
              <p>- Sarah Williams, Marketing Director at MarketWise</p>
            </div>
          </div>

          <div className="home-cta">
            <h2>Ready to Get Started?</h2>
            <p>
              Partner with Prime Solutions today and take your business to the
              next level. Contact us for a consultation, and let's make your
              vision a reality.
            </p>
            <a href="#contact" className="cta-button">
              Contact Us
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
