import React from "react";
import { Link } from "react-router-dom";
import "./services.css";

import uiux from "../../assets/ui-ux-design.webp";
import mob from "../../assets/mobile-development.webp";
import devops from "../../assets/devops.webp";
import aiml from "../../assets/ai-and-ml.webp";
import Footer from "../footer/Footer";

const Services = () => {
  return (
    <>
      <div className="services-container">
        <div className="services-inner">
          <p className="services-p1">Services We Provide</p>
          <p className="services-p2">
            Our approach is simple: Everything is human-centric, whether it's UI,
            UX, or any other aspect of our service.
          </p>
          <div className="service-cards">
            <Link to="/services/devops" className="card">
              <div className="service-img-div">
                <img src={devops} className="service-img" alt="DevOps" />
              </div>
              <p className="card-p">DevOps</p>
            </Link>
            <Link to="/services/mobile" className="card">
              <div className="service-img-div">
                <img src={mob} className="service-img" alt="Mobile Development" />
              </div>
              <p className="card-p">Mobile Development</p>
            </Link>
            <Link to="/services/uiux" className="card">
              <div className="service-img-div">
                <img src={uiux} className="service-img" alt="UI/UX Design" />
              </div>
              <p className="card-p">UI/UX Design</p>
            </Link>
            <Link to="/services/aiml" className="card">
              <div className="service-img-div">
                <img src={aiml} className="service-img" alt="AI & ML" />
              </div>
              <p className="card-p">AI & ML</p>
            </Link>
            <Link to="/services/seo" className="card">
              <div className="service-img-div">
                <img src={uiux} className="service-img" alt="SEO" />
              </div>
              <p className="card-p">SEO</p>
            </Link>
            <Link to="/services/digital" className="card">
              <div className="service-img-div">
                <img src={mob} className="service-img" alt="Digital Marketing" />
              </div>
              <p className="card-p">Digital Marketing</p>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Services;
