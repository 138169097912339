import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./pages/footer/Footer";
import Home from "./pages/home/Home";
import Navbar from "./pages/navbar/Navbar";
import Partner from "./pages/partnership/Partner";
import Services from "./pages/services/Services";
import Testimonials from "./pages/testimonials/Testimonials";
import Work from "./pages/work/Work";
import ServiceDetail from "./pages/service/ServiceDetail";

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/services/:serviceId" element={<ServiceDetail />} />

        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/partners" element={<Partner />} />
        <Route path="/work" element={<Work />} />
        {/* <Route path="/contact" element={<Footer />} /> */}
      </Routes>
    </>
  );
}

export default App;
