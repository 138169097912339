import React from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import Footer from "../footer/Footer";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  background: linear-gradient(135deg, #f6d365 0%, #fda085 100%);
  min-height: 100vh;
  color: #fff;
  font-family: "Arial", sans-serif;
`;

const Title = styled(animated.h1)`
  font-size: 2rem;
  margin-bottom: 20px;
`;

const Description = styled(animated.p)`
  font-size: 1rem;
  max-width: 800px;
  text-align: center;
  margin-bottom: 40px;
`;

const Icon = styled(animated.div)`
  font-size: 4rem;
  margin: 20px 0;
`;

const Button = styled(animated.button)`
  background: #ff7f50;
  border: none;
  padding: 15px 30px;
  font-size: 1.2rem;
  color: #fff;
  cursor: pointer;
  border-radius: 50px;
  transition: background 0.3s ease;

  &:hover {
    background: #ff6347;
  }
`;

const PlanningIcon = () => (
  <Icon role="img" aria-label="planning">
    🗂️
  </Icon>
);

const PassionIcon = () => (
  <Icon role="img" aria-label="passion">
    🔥
  </Icon>
);

const VisionIcon = () => (
  <Icon role="img" aria-label="vision">
    🌟
  </Icon>
);

const Work = () => {
  const titleSpring = useSpring({
    from: { opacity: 0, transform: "translateY(-50px)" },
    to: { opacity: 1, transform: "translateY(0)" },
    config: { tension: 200, friction: 12 },
  });

  const descriptionSpring = useSpring({
    from: { opacity: 0, transform: "translateY(50px)" },
    to: { opacity: 1, transform: "translateY(0)" },
    delay: 300,
    config: { tension: 200, friction: 12 },
  });

  const iconSpring = useSpring({
    from: { opacity: 0, transform: "scale(0.8)" },
    to: { opacity: 1, transform: "scale(1)" },
    delay: 500,
    config: { tension: 200, friction: 10 },
  });

  const buttonSpring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 800,
    config: { tension: 200, friction: 10 },
  });

  return (
    <>
      <Container>
        <Title style={titleSpring}>How We Work</Title>
        <Description style={descriptionSpring}>
          We believe in meticulous planning, working with passion, and always
          having a clear vision. At our software house, every project is a
          journey toward excellence.
        </Description>
        <PlanningIcon style={iconSpring} />
        <PassionIcon style={iconSpring} />
        <VisionIcon style={iconSpring} />
        <Button style={buttonSpring}>Let's Collaborate</Button>
      </Container>
      <Footer />
    </>
  );
};

export default Work;
