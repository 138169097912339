import React from "react";
import "./testimonials.css";

import client1 from "../../assets/partner.webp";
import client2 from "../../assets/partner.webp";
import client3 from "../../assets/partner.webp";
import Footer from "../footer/Footer";

const Testimonials = () => {
  return (
    <>
      <div className="testimonials-container">
        <div className="testimonials-inner">
          <p className="testimonials-title">What Our Clients Say</p>
          <div className="testimonial-cards">
            <div className="testimonial-card">
              <div className="testimonial-img-div">
                <img src={client1} className="testimonial-img" alt="Client 1" />
              </div>
              <p className="testimonial-name">John Doe</p>
              <p className="testimonial-text">
                "Prime Solutions delivered exceptional results on our web
                development project. Their team is knowledgeable, responsive,
                and committed to exceeding our expectations."
              </p>
            </div>
            <div className="testimonial-card">
              <div className="testimonial-img-div">
                <img src={client2} className="testimonial-img" alt="Client 2" />
              </div>
              <p className="testimonial-name">Jane Smith</p>
              <p className="testimonial-text">
                "Working with Prime Solutions was a game-changer for our mobile
                app development. Their innovative approach and attention to
                detail set them apart from the competition."
              </p>
            </div>
            <div className="testimonial-card">
              <div className="testimonial-img-div">
                <img src={client3} className="testimonial-img" alt="Client 3" />
              </div>
              <p className="testimonial-name">Michael Johnson</p>
              <p className="testimonial-text">
                "The team at Prime Solutions went above and beyond to deliver an
                outstanding UI/UX design for our product. We couldn't be happier
                with the results!"
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Testimonials;
